<template>
  <div :class="['g-content', isPC ? 'z-web' : 'z-phone',theme.primaryColor ? theme.primaryColor : '']">
    <VisualItem
      v-if="item.title && item.title.value"
      :visualKeys="[item.title.visualKeys, item.textColor.visualKeys]"
      :style="item.textColor && item.textColor.value == 'black' ? 'color: #000' : 'color : #fff'"
      @visual="handleVisual"
      class-name="u-title"
    >
      {{ item.title.value }}
    </VisualItem>
    <VisualItem
      v-if="item.subTitle && item.subTitle.value"
      :visualKeys="[item.subTitle.visualKeys, item.textColor.visualKeys]"
      :style="item.textColor && item.textColor.value == 'black' ? 'color: #000' : 'color : #fff'"
      @visual="handleVisual"
      class-name="u-subtitle"
    >
      {{ item.subTitle.value }}
    </VisualItem>
    <VisualItem
      v-if="item.btn.value && item.link.value"
      :visualKeys="[item.btn.visualKeys, item.link.visualKeys, item.btnColor.visualKeys]"
      @visual="handleVisual"
    >
      <CMSLink
        v-if="item.btn.value && item.link.value"
        :link="item.link.value"
        :class-name="[(item.btnColor && item.btnColor.value) && item.btnColor.value == 'black' ? 'blackBorder' : item.btnColor.value == 'white' ? 'whiteBorder' : '','u-btn']"
      >
        {{ item.btn.value }}
      </CMSLink>
    </VisualItem>
  </div>
</template>

<script>
import VisualItem from '~/components/common/VisualItem'
import CMSLink from '~/components/common/Link'

export default {
  name: 'BannerContent',
  components: {
    VisualItem,
    CMSLink
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss" scoped>

@import '~/assets/css/4mixin.css';

.g-content {
  &.z-phone {
    display: inline-block;

    .whiteBorder {
      background: transparent !important;
      border: 1px solid #fff !important;
      color: #fff !important;

      &:active {
        background: rgba(255, 255, 255, 0.2) !important;
      }
    }

    .blackBorder {
      background: transparent !important;
      border: 1px solid #000 !important;
      color: #000 !important;
    }

    .u-btn {
      position: relative;
      border: none;
    }

    .u-btn:active::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 10%);
    }

    &.red {
      .u-btn {
        background-color: $baseRed;
      }
    }
  }

  &.z-web {
    display: inline-block;

    .whiteBorder {
      background: transparent !important;
      border: 1px solid #fff !important;
      color: #fff !important;
    }

    .blackBorder {
      background: transparent !important;
      border: 1px solid #000 !important;
      color: #000 !important;
    }

    .u-btn {
      position: relative;
      border: none;
    }

    .u-btn:hover::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 5%);
    }

    .u-btn:active::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 10%);
    }

    &.red {
      .u-btn {
        background-color: $baseRed;
      }
    }
  }
}
</style>
